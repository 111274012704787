import axios from "axios";
import { useQuery } from "react-query";
import { getToken } from "../../Common/utils";
import { API_URL } from "../../Configs/api.config";
import LeosaTable from "./leosa-table";

const ManageLEOSA = () => {
  const { data, isLoading, isError } = useQuery("/leosa-requests", async () => {
    const token = getToken();
    const { data } = await axios.get(`${API_URL}/development/leosa-requests`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  });

  if (isLoading) {
    return <p>Loading</p>;
  }

  if (isError) {
    return <p>Could not get LEOSA Requests.</p>;
  }

  console.log(data);

  return (
    <div className="container">
      <div className="row">
        <div className={"col-12"} style={{ paddingTop: 20, marginTop: 20 }}>
          <h1>Manage LEOSA Requests</h1>
          <LeosaTable leosaRequests={data} />
        </div>
      </div>
    </div>
  );
};

export default ManageLEOSA;
