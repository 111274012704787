import React, { useState, useEffect } from "react";
import { getToken } from "../../Common/utils";

import { API_URL } from "../../Configs/api.config";

const BusinessOffers = () => {
  const [businessOffers, setBusinessOffers] = useState([]);
  const [apnAPIMessage, setApnAPIMessage] = useState(null);

  const getBusinessOffers = async () => {
    const token = getToken();

    const res = await fetch(`${API_URL}/development/business-offers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    setBusinessOffers(data);
  };

  const approve = async (id) => {
    const token = getToken();

    const res = await fetch(
      `${API_URL}/development/approve-business-offer?id=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.json();

    if (data.status === "active") {
      setApnAPIMessage({
        success: true,
        message: "Approved Business Offer",
      });
      await getBusinessOffers();
    } else {
      setApnAPIMessage({
        success: false,
        message: data.message,
      });
    }
  };

  useEffect(() => {
    getBusinessOffers();
  }, []);

  return (
    <div className="container">
      {" "}
      <div className="row">
        <div className={"col-12"} style={{ marginTop: 20, paddingTop: 20 }}>
          <h1>Business Offers In Review</h1>
        </div>
        <div className={"col-12"}>
          <div className={"table-responsive"}>
            <table className={"table"}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Redeem Method</th>
                  <th>Image URL</th>
                  <th>Approve</th>
                </tr>
              </thead>
              <tbody>
                {businessOffers.map((businessOffer) => (
                  <tr key={businessOffer.id}>
                    <td>{businessOffer.createdAt}</td>
                    <td>{businessOffer.title}</td>
                    <td>
                      <div
                        style={{
                          margin: "0 auto",
                          height: 200,
                          width: 200,
                          overflowY: "scroll",
                          whiteSpace: "normal",
                        }}
                      >
                        {businessOffer.description}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          margin: "0 auto",
                          height: 200,
                          width: 200,
                          overflowY: "scroll",
                          whiteSpace: "normal",
                        }}
                      >
                        {businessOffer.redeemMethod}
                      </div>
                    </td>
                    <td>
                      <a
                        href={`https://usapacsstorage.s3.us-east-2.amazonaws.com/${businessOffer.s3Key}`}
                      >
                        DOWNLOAD
                      </a>
                    </td>
                    <td>
                      <button
                        className={"btn btn-success"}
                        onClick={() => {
                          approve(businessOffer.id);
                        }}
                      >
                        APPROVE
                      </button>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {apnAPIMessage ? (
          <div className={"col-12"}>
            <p
              id={"apn-api-message"}
              style={{ color: apnAPIMessage.success ? "green" : "red" }}
            >
              {apnAPIMessage.message}
            </p>
          </div>
        ) : null}
      </div>{" "}
    </div>
  );
};

export default BusinessOffers;
