import React, { useState, useEffect } from "react";

import UsersSelect from "../UsersSelect/UsersSelect";
import { API_URL } from "../../Configs/api.config";

const SeedBusinesses = () => {
  const [ownerUId, setOwnerUId] = useState(null);
  const [apnAPIMessage, setApnAPIMessage] = useState(null);

  const latRef = React.useRef(null);
  const longRef = React.useRef(null);
  const keywordRef = React.useRef(null);

  const seedBusinesses = async () => {
    setApnAPIMessage(null);

    if (ownerUId == null) {
      setApnAPIMessage({
        success: false,
        message: "Please select a user to give ownership.",
      });
      return;
    }

    if (latRef.current.value == "" || longRef.current.value == "") {
      setApnAPIMessage({
        success: false,
        message: "Please enter latitude and longitude as decimal numbers.",
      });
      return;
    }

    const res = await fetch(
      `${API_URL}/development/seed-businesses?ownerUId=${ownerUId}&startingLat=${latRef.current.value}&startingLong=${longRef.current.value}&keyword=${keywordRef.current.value}`
    );

    const data = await res.json();

    if (data.success !== true) {
      setApnAPIMessage({
        success: false,
        message: data.message,
      });
    } else {
      setApnAPIMessage({
        success: true,
        message: `Inserted ${data.inserted} businesses in database. ${data.duplicates} duplicates were found and not inserted`,
      });
    }
  };

  return (
    <div className="container">
      {" "}
      <div className="row">
        <div className={"col-12"} style={{ marginTop: 20, paddingTop: 20 }}>
          <h1>Seed Businesses With Automated Data</h1>
        </div>
        <div className={"col-12"}>
          <span style={{ fontSize: 12 }}>
            Can get Coordinates from{" "}
            <a target={"_blank"} href={"https://www.google.com/maps/"}>
              Google Maps
            </a>{" "}
            by right-clicking
          </span>
          <p>
            Latitude: <br />
            <input type={"text"} ref={latRef} />{" "}
          </p>
          <p>
            Longitude: <br />
            <input type={"text"} ref={longRef} />{" "}
          </p>
          <p>
            Business Keyword (Searches for business that include this keyword)
            (optional): <br />
            <input type={"text"} ref={keywordRef} />{" "}
          </p>
          <p>
            Set Ownership To: <br />
            <UsersSelect setChosenUser={setOwnerUId} />
          </p>
        </div>
        <div className={"col-12"}>
          <button
            onClick={() => {
              seedBusinesses();
            }}
            type={"button"}
            className={"btn btn-primary"}
            style={{ width: 200, fontSize: 14 }}
          >
            SUBMIT
          </button>
        </div>
        {apnAPIMessage ? (
          <div className={"col-12"}>
            <p
              id={"apn-api-message"}
              style={{ color: apnAPIMessage.success ? "green" : "red" }}
            >
              {apnAPIMessage.message}
            </p>
          </div>
        ) : null}
      </div>{" "}
    </div>
  );
};

export default SeedBusinesses;
