import { getToken } from "../../Common/utils";
import axios from "axios";
import { API_URL } from "../../Configs/api.config";
import papa from "papaparse";

export const exportUsers = async () => {
  const token = getToken();

  const { data } = await axios.get(`${API_URL}/development/get-all-users`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const users = [];

  users.push(
    ...data.map((user) => ({
      Email: user.email,
      "First Name": user.firstName,
      "Last Name": user.lastName,
    }))
  );

  const csv = papa.unparse(users);
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  let csvURL = null;

  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, "download.csv");
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", "download.csv");
  tempLink.click();
};
