import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_URL } from "../../../Configs/api.config";
import { getToken } from "../../../Common/utils";

const EditCategory = () => {
  const { id } = useParams();

  const [category, setCategory] = useState(null);

  const history = useHistory();

  const getCategory = async () => {
    const token = getToken();
    const res = await fetch(
      `${API_URL}/development/category?categoryId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const body = await res.json();

    if (body.message) {
      alert(body.message);
      return;
    }

    setCategory(body);
  };

  const updateCategory = async (values) => {
    const data = {
      id,
      name: values.category_name,
      imageUrl: values.image_url,
      leosaOnly: values.leosa_only ? "1" : "0",
    };

    console.log(data);

    const token = getToken();

    const res = await fetch(`${API_URL}/development/update-category`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const body = await res.json();

    if (body.message) {
      alert(body.message);
      return;
    }

    history.push("/articles");
  };

  useEffect(() => {
    getCategory();
  }, []);

  console.log(category);

  return !category ? null : (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Link to="/articles">
            <button className="btn btn-primary">BACK</button>
          </Link>
        </div>
        <div className="col-12">
          <Formik
            initialValues={{
              category_name: category.name,
              image_url: category.imageUrl,
              leosa_only: category.leosaOnly === 1,
            }}
            onSubmit={async (values) => {
              updateCategory(values);
            }}
          >
            <Form>
              <div className="field-area">
                <label>Category Name</label>
                <Field
                  type="text"
                  className="form-control"
                  name="category_name"
                />
              </div>
              <div className="field-area">
                <label>Image URL</label>
                <Field type="text" className="form-control" name="image_url" />
              </div>
              <div className="field-area">
                <label>LEOSA Only</label>
                <Field type="checkbox" name="leosa_only" />
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                SAVE
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
