import { Formik, Form, Field } from "formik";
import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { isLogin, login } from "../../Common/utils";
import { API_URL } from "../../Configs/api.config";

const Login = () => {
  const [refresh, setRefresh] = useState(false);

  const handleLogin = async (values) => {
    const res = await fetch(`${API_URL}/development/login`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const body = await res.json();

    if (body.token) {
      console.log("logging in");
      login(body.token);
      setRefresh(true);
    } else {
      alert(body.error);
    }
  };
  return isLogin() ? (
    <Redirect to="/" />
  ) : (
    <div>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={async (values) => {
          handleLogin(values);
        }}
      >
        {({ errors }) => (
          <Form>
            <div className="field-area">
              <label>Username</label>
              <Field type="text" className="form-control" name="username" />
              <label>Password</label>
              <Field type="password" className="form-control" name="password" />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              style={{ marginTop: 20 }}
            >
              SAVE
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
