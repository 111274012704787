import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { API_URL } from "../../../Configs/api.config";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../Common/utils";

const MaxAmountBannerAds = () => {
  const history = useHistory();
  const [maxAmount, setMaxAmountState] = useState(null);

  const getMaxAmount = async () => {
    const data = {
      newAmount: 20,
    };

    const token = getToken();
    const res = await fetch(
      `${API_URL}/development/get-max-amount-banner-ads`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const body = await res.json();

    console.log(body);

    if (body.message) {
      alert(body.message);
      return;
    }

    setMaxAmountState(body.value);
  };

  const saveMaxAmount = async (values) => {
    const data = {
      newAmount: values.maxAmount,
    };

    const token = getToken();

    const res = await fetch(
      `${API_URL}/development/change-max-amount-banner-ads`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const body = await res.json();

    if (body.message) {
      alert(body.message);
      return;
    }

    history.push("/banner-advertisements");
  };

  useEffect(() => {
    getMaxAmount();
  }, []);

  console.log(maxAmount);

  return maxAmount === null ? null : (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Formik
            initialValues={{
              maxAmount: maxAmount,
            }}
            onSubmit={async (values) => {
              saveMaxAmount(values);
            }}
          >
            <Form>
              <div className="field-area">
                <label>Max Amount of Banner Advertisements</label>
                <Field name="maxAmount" type="text" className="form-control" />
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                SAVE
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MaxAmountBannerAds;
