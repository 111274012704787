import moment from "moment";
import { useMutation } from "react-query";
import UserRow from "./user-row.jsx";

const UsersTable = ({ users, sortBy }) => {
  let data = users;

  return (
    <div className="table-responsive">
      <table className="table" style={{ fontSize: 14 }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>UID</th>
            <th>Date Created</th>
            <th>Type</th>
            <th>Username</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Bullets</th>
            <th>Badges Posted</th>
            <th>Referrals Received</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <UserRow user={user} key={user.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
