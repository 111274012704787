import ActionButton from "./action-button";

const LeosaRow = ({ leosaRequest }) => {
  return (
    <tr>
      <td>{leosaRequest.createdAt}</td>
      <td>{leosaRequest.email}</td>
      <td>{leosaRequest.department}</td>
      <td>{leosaRequest.unit}</td>
      <td>{leosaRequest.rank}</td>
      <td>
        <img
          src={leosaRequest.frontImageUrl}
          alt="front"
          width={100}
          height="50"
        />
        <a href={leosaRequest.frontImageUrl}>
          <p>DOWNLOAD</p>
        </a>
      </td>
      <td>
        <img
          src={leosaRequest.backImageUrl}
          alt="front"
          width={100}
          height="50"
        />
        <a href={leosaRequest.backImageUrl}>
          <p>DOWNLOAD</p>
        </a>
      </td>
      <td>
        <ActionButton
          action={"Approve"}
          id={leosaRequest.id}
          email={leosaRequest.email}
        />
      </td>
      <td>
        <ActionButton
          action={"Deny"}
          id={leosaRequest.id}
          email={leosaRequest.email}
        />
      </td>
    </tr>
  );
};

export default LeosaRow;
