import React, { useState } from "react";

import "./Dashboard.css";
import { Link, Redirect } from "react-router-dom";
import { isLogin, logout } from "../../Common/utils";

const Dashboard = () => {
  const [refresh, setRefresh] = useState(false);

  const handleLogout = () => {
    logout();
    setRefresh(true);
  };

  return isLogin() == false ? (
    <Redirect to="/login" />
  ) : (
    <div className="container">
      <div className="row" id={"main-section"}>
        <div className="col-12">
          <ul className="menu">
            <li>
              <Link className="menu-option-link" to="/banner-advertisements">
                Banner Advertisements
              </Link>
            </li>
            <li>
              <Link className="menu-option-link" to="/special-offers">
                Special Offers
              </Link>
            </li>
            <li>
              <Link className="menu-option-link" to="/manage-users">
                Manage Users
              </Link>
            </li>
            <li>
              <Link className="menu-option-link" to="/manage-leosa">
                Manage LEOSA Requests
              </Link>
            </li>
            <li>
              <Link className="menu-option-link" to="/business-claims">
                Business Claims
              </Link>
            </li>
            <li>
              <Link className="menu-option-link" to="/articles">
                Manage Articles
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleLogout();
                }}
              >
                LOGOUT
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
