import React from "react";
import { Formik, Form, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import { API_URL } from "../../../Configs/api.config";
import { getToken } from "../../../Common/utils";

const AddCategory = () => {
  let history = useHistory();

  console.log(history);

  const saveCategory = async (values) => {
    const data = {
      name: values.category_name,
      isLeosa: values.leosa_only,
      imageUrl: values.image_url,
    };

    const token = getToken();

    console.log(`token = ${token}`);

    const res = await fetch(`${API_URL}/development/add-category`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const body = await res.json();

    if (body.message) {
      alert(`${body.message}`);
      return;
    }

    history.push("/articles");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Link to="/articles">
            <button className="btn btn-primary">BACK</button>
          </Link>
        </div>
        <div className="col-12">
          <Formik
            initialValues={{ name: "", image_url: "", leosa_only: false }}
            onSubmit={async (values) => {
              saveCategory(values);
            }}
          >
            <Form>
              <div className="field-area">
                <label>Category Name</label>
                <Field
                  type="text"
                  className="form-control"
                  name="category_name"
                />
              </div>
              <div className="field-area">
                <label>Image URL</label>
                <Field type="text" className="form-control" name="image_url" />
              </div>
              <div className="field-area">
                <label>LEOSA Only</label>
                <Field type="checkbox" name="leosa_only" />
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                SAVE
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
