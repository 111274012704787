import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getToken } from "../../../Common/utils";

import { API_URL } from "../../../Configs/api.config";

const Articles = () => {
  const { id } = useParams();

  const [category, setCategory] = useState(null);

  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    const token = getToken();

    const res = await fetch(
      `${API_URL}/development/category/articles?categoryId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.json();

    setArticles(data);
  };

  const getCategory = async () => {
    console.log("getting category");
    try {
      const token = getToken();
      const res = await fetch(
        `${API_URL}/development/category?categoryId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = await res.json();

      console.log(data);

      setCategory(data);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteArticle = async (id) => {
    const data = { id };

    const token = getToken();

    const res = await fetch(`${API_URL}/development/delete-article`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const body = await res.json();

    if (body.message) {
      alert(body.message);
      return;
    }

    let tmp = articles.filter((a) => a.id !== id);
    setArticles(tmp);
  };

  useEffect(() => {
    getCategory();
    getArticles();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12" style={{ paddingBottom: 20 }}>
          <Link to={`/articles`}>
            <button type="button" className="btn btn-primary">
              BACK
            </button>
          </Link>
        </div>
        <div className="col-12">
          <Link to={`/articles/${id}/add-article`}>
            <button type="button" className="btn btn-success">
              ADD ARTICLE
            </button>
          </Link>
        </div>
        <div className="col-12" style={{ paddingBottom: 20 }}>
          <h1>Articles</h1>
          <h2 style={{ color: "grey" }}>{category ? category.name : null}</h2>
        </div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-8">Title</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {articles.map((article) => (
                  <tr key={article.id}>
                    <td>
                      <span
                        style={{
                          display: "inline-block",
                          maxWidth: 500,
                          maxHeight: 100,
                          whiteSpace: "pre-wrap",
                          overflowY: "scroll",
                        }}
                      >
                        {article.title}
                      </span>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          deleteArticle(article.id);
                        }}
                      >
                        DELETE
                      </button>
                    </td>
                    <td>
                      <Link to={`/article/${id}/${article.id}`}>
                        <button type="button" className="btn btn-primary">
                          EDIT
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
