import "./App.css";

import { QueryClient, QueryClientProvider } from "react-query";

import Dashboard from "./Components/Dashboard/Dashboard";
import BannerAds from "./Components/BannerAds/BannerAds";
import BusinessOffers from "./Components/BusinessOffers/BusinessOffers";
import ManageUsers from "./Components/ManageUsers/ManageUsers";
import RecentClaims from "./Components/RecentClaims/RecentClaims";
import APNs from "./Components/APNs/APNs";
import SeedBusinesses from "./Components/SeedBusinesses/SeedBusinesses";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import logo from "./Assets/logo.png";
import ArticleManager from "./Components/ArticleManager/ArticleManager";
import Articles from "./Components/ArticleManager/Components/Articles";
import Article from "./Components/ArticleManager/Components/Article";
import AddArticle from "./Components/ArticleManager/Components/AddArticle";
import AddCategory from "./Components/ArticleManager/Components/AddCategory";
import EditCategory from "./Components/ArticleManager/Components/EditCategory";
import Review from "./Components/BannerAds/Components/Review";
import MaxAmountBannerAds from "./Components/BannerAds/Components/MaxAmountBannerAds";
import PrivateRoute from "./Common/PrivateRoute";
import Login from "./Components/Login/Login";

import ManageLEOSA from "./Components/ManageLEOSA/ManageLEOSA";

const client = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <Router basename={"app-admin"}>
          <div className="container">
            <div className="row" id={"main-section"}>
              <div className={"col-12"}>
                <Link to="/">
                  <img id={"logo"} src={logo} alt="USA PACS" />
                </Link>
              </div>
            </div>
          </div>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute
              exact
              path="/banner-advertisements"
              component={BannerAds}
            />
            <PrivateRoute
              exact
              path="/banner-advertisements/review"
              component={Review}
            />
            <PrivateRoute
              exact
              path="/banner-advertisements/max-amount"
              component={MaxAmountBannerAds}
            />
            <PrivateRoute
              exact
              path="/special-offers"
              component={BusinessOffers}
            />
            <PrivateRoute exact path="/manage-users" component={ManageUsers} />
            <PrivateRoute
              exact
              path="/business-claims"
              component={RecentClaims}
            />
            <PrivateRoute exact path="/push-notifications" component={APNs} />
            <PrivateRoute
              exact
              path="/insert-businesses"
              component={SeedBusinesses}
            />
            <PrivateRoute exact path="/articles" component={ArticleManager} />
            <PrivateRoute exact path="/articles/:id" component={Articles} />
            <PrivateRoute
              exact
              path="/article/:categoryId/:id"
              component={Article}
            />
            <PrivateRoute
              exact
              path="/articles/:id/add-article"
              component={AddArticle}
            />
            <PrivateRoute exact path="/add-category" component={AddCategory} />
            <PrivateRoute
              exact
              path="/edit-category/:id"
              component={EditCategory}
            />
            <PrivateRoute exact path="/manage-leosa" component={ManageLEOSA} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
