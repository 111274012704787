import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../Common/utils";

import { API_URL } from "../../Configs/api.config";

const BannerAds = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul className="menu">
            <li>
              <Link
                className="menu-option-link"
                to="/banner-advertisements/review"
              >
                In Review
              </Link>
            </li>
            <li>
              <Link
                className="menu-option-link"
                to="/banner-advertisements/max-amount"
              >
                Edit Max Amount
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BannerAds;
