import React, { useState, useEffect } from "react";

import UsersSelect from "../UsersSelect/UsersSelect";
import { API_URL } from "../../Configs/api.config";

const APNs = () => {
  const [isSandbox, setIsSandbox] = useState(false);
  const [apnAPIMessage, setApnAPIMessage] = useState(null);
  const [chosenUser, setChosenUser] = useState(null);

  const triggerOfferAPN = async () => {
    if (chosenUser === null && chosenUser !== "") {
      setApnAPIMessage({
        message: "Please select a user.",
        success: false,
      });
      return;
    }

    const url = `${API_URL}/development/trigger-apn?user_uid=${chosenUser}&isSandbox=${
      isSandbox ? "1" : "0"
    }&apn_type=offers`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).catch((error) => {
      console.log(error);
    });

    const data = await res.json();

    if (data.success == true) {
      setApnAPIMessage({
        success: true,
        message: "Sent out Push Notification.",
      });

      return;
    }

    setApnAPIMessage({
      success: false,
      message: data.message,
    });
  };

  const triggerReportsAPN = async () => {
    if (chosenUser === null && chosenUser !== "") {
      setApnAPIMessage({
        message: "Please select a user.",
        success: false,
      });
      return;
    }

    const res = await fetch(
      `${API_URL}/development/trigger-apn?user_uid=${chosenUser}&isSandbox=${
        isSandbox ? "1" : "0"
      }&apn_type=reports`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    const data = await res.json();

    if (data.success == true) {
      setApnAPIMessage({
        success: true,
        message: "Sent out Push Notification.",
      });

      return;
    }

    setApnAPIMessage({
      success: false,
      message: data.message,
    });
  };

  const handleSandboxCheckbox = (e) => {
    setIsSandbox(!isSandbox);
  };

  return (
    <div className="container">
      {" "}
      <div className="row">
        <div className={"col-12"}>
          <h1>Push Notifications</h1>
          <p>
            APN Sandbox:(local development only):{" "}
            <input
              type={"checkbox"}
              onChange={(e) => {
                handleSandboxCheckbox(e);
              }}
              checked={isSandbox}
            />
          </p>
          <p>
            Send Notification To:
            <UsersSelect setChosenUser={setChosenUser} />
          </p>
        </div>
        <div className="col-12">
          <button
            className={"btn btn-primary option-btn"}
            type="button"
            onClick={() => {
              triggerOfferAPN();
            }}
          >
            Trigger Offers Push Notification
          </button>
        </div>
        <div className="col-12">
          <button
            className={"btn btn-primary option-btn"}
            type="button"
            onClick={() => {
              triggerReportsAPN();
            }}
          >
            Trigger Reports Push Notification
          </button>
        </div>
        {apnAPIMessage ? (
          <div className={"col-12"}>
            <p
              id={"apn-api-message"}
              style={{ color: apnAPIMessage.success ? "green" : "red" }}
            >
              {apnAPIMessage.message}
            </p>
          </div>
        ) : null}
      </div>{" "}
    </div>
  );
};

export default APNs;
