import LeosaRow from "./leosa-row";

const LeosaTable = ({ leosaRequests }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Email</th>
            <th>Department</th>
            <th>Unit</th>
            <th>Rank</th>
            <th>Front</th>
            <th>Back</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {leosaRequests.map((request) => (
            <LeosaRow key={request.id} leosaRequest={request} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeosaTable;
