import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import { API_URL } from "../../../Configs/api.config";
import { getToken } from "../../../Common/utils";

const Article = () => {
  const { id, categoryId } = useParams();
  const history = useHistory();

  const [article, setArticle] = useState(null);

  const getArticle = async () => {
    const token = getToken();
    const res = await fetch(`${API_URL}/development/article?articleId=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const article = await res.json();

    setArticle(article);
  };

  const updateArticle = async (values) => {
    let data = {
      id,
      title: values.title,
    };

    if (article.type === "internal") {
      data["content"] = values.content;
      data["imageURL"] = values.imageURL ?? "";
    }
    if (article.type === "external") {
      data["externalLink"] = values.externalLink ?? "";
    }

    const token = getToken();
    const res = await fetch(`${API_URL}/development/update-article`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const body = await res.json();

    if (body.message) {
      alert(body.message);
      return;
    }

    history.push(`/articles/${categoryId}`);
  };

  useEffect(() => {
    getArticle();
  }, []);

  return !article ? (
    <div></div>
  ) : (
    <div className="container">
      <div className="row">
        <div className="col-12" style={{ paddingBottom: 20 }}>
          <Link to={`/articles/${article.categoryId}`}>
            <button type="button" className="btn btn-primary">
              BACK
            </button>
          </Link>
        </div>
        <div className="col-12">
          <Formik
            initialValues={{
              title: article.title,
              content: article.content,
              imageURL: article.imageURL ?? "",
              externalLink: article.externalLink ?? "",
            }}
            onSubmit={async (values) => {
              updateArticle(values);
            }}
          >
            <Form>
              <div className="form-group">
                <div className="field-area">
                  <label>Title</label>
                  <Field name="title" type="text" className="form-control" />
                </div>
                {article.type === "internal" ? (
                  <React.Fragment>
                    <div className="field-area">
                      <label style={{ marginTop: 40 }}>Content</label>
                      <Field
                        name="content"
                        as="textarea"
                        className="form-control"
                      />
                    </div>
                    <div className="field-area">
                      <label>Image URL</label>
                      <Field
                        name="imageURL"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="field-area">
                      <label>External Link</label>
                      <Field
                        name="externalLink"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </React.Fragment>
                )}
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Article;
