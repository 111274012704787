import React, { useEffect, useState } from "react";
import { API_URL } from "../../Configs/api.config";

import Categories from "./Components/Categories";

const ArticleManager = () => {
  return (
    <div className="container">
      <Categories />
    </div>
  );
};

export default ArticleManager;
