import React, { useState, useEffect, useRef } from "react";

import UsersTable from "./components/users-table";
import { API_URL } from "../../Configs/api.config";
import { getToken } from "../../Common/utils";
import { useInfiniteQuery, useQuery } from "react-query";
import axios from "axios";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { exportUsers } from "./export-users";

const ManageUsers = () => {
  const [sortBy, setSortBy] = useState("createdAt");
  const [dir, setDir] = useState("DESC");

  const { data, isLoading, isError, fetchNextPage, isFetching, hasNextPage } =
    useInfiniteQuery(
      ["/users", sortBy, dir],
      async ({ pageParam = 0 }) => {
        const token = getToken();

        const { data } = await axios.get(
          `${API_URL}/development/get-users?orderBy=${sortBy}&page=${pageParam}&dir=${dir}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return data;
      },
      {
        refetchOnMount: false,
        refetchIntervalInBackground: false,
        refetchOnReconnect: false,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        // staleTime: 1000 * 60 * 2,
      }
    );

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !!hasNextPage,
  });

  if (isLoading) {
    return <p>Loading Users</p>;
  }

  if (isError) {
    return <p>Couldn't get users</p>;
  }

  const users = [];
  if (data) {
    data.pages.forEach((page) => {
      users.push(...page.users);
    });
  }

  return (
    <div className="container-fluid" style={{ textAlign: "center" }}>
      <div className="row">
        <div className={"col-12"} style={{ paddingTop: 20, marginTop: 20 }}>
          <h1>Manage Users</h1>
          <p className="usapacs-form-label">SORT BY:</p>
          <select
            value={sortBy}
            className="form-control usapacs-select"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="bullets">Bullets</option>
            <option value="referralsReceived">Referrals</option>
            <option value="username">Username</option>
            <option value="createdAt">Date Created</option>
            <option value="accountTypeId">Account Type</option>
            <option value="firstName">First Name</option>
            <option value="lastName">Last Name</option>
          </select>
          <p className="usapacs-form-label">DIRECTION:</p>
          <select
            style={{ marginBottom: 60 }}
            value={dir}
            className="form-control usapacs-select"
            onChange={(e) => setDir(e.target.value)}
          >
            <option value="ASC">ASCENDING</option>
            <option value="DESC">DESCENDING</option>
          </select>

          <button
            style={{
              marginBottom: 40,
              background: "#324368",
              border: "none",
              color: "white",
              padding: 10,
              borderRadius: 3,
              maxWidth: 200,
              width: "100%",
            }}
            onClick={() => exportUsers(data)}
          >
            EXPORT
          </button>

          <UsersTable users={users} sortBy={sortBy} />
          {hasNextPage ? (
            <button
              ref={loadMoreRef}
              disabled={isFetching || isLoading}
              style={{
                background: !isFetching ? "#324368" : "#A2ACC1",
                border: "none",
                color: "white",
                padding: 10,
                borderRadius: 3,
                marginBottom: 40,
                maxWidth: 200,
                width: "100%",
              }}
              onClick={() => fetchNextPage()}
            >
              Fetch More Users
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
