import React, { useState, useEffect } from "react";
import { getToken } from "../../Common/utils";

import { API_URL } from "../../Configs/api.config";

const UsersSelect = ({ setChosenUser }) => {
  const [users, setUsers] = useState([]);

  const token = getToken();

  const getUsers = async () => {
    const res = await fetch(`${API_URL}/development/get-users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    setUsers(data);
  };

  const handleUserSelect = async (user_uid) => {
    setChosenUser(user_uid);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <select onChange={(e) => handleUserSelect(e.target.value)}>
      <option value={""}>SELECT</option>
      {users.map((user) => (
        <option key={user.id} value={user.uid}>
          {user.username}
        </option>
      ))}
    </select>
  );
};

export default UsersSelect;
