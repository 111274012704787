import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../../Common/utils";

import { API_URL } from "../../../Configs/api.config";

const Review = () => {
  const [bannerAds, setBannerAds] = useState([]);
  const [apnAPIMessage, setApnAPIMessage] = useState(null);

  const getBannerAds = async () => {
    const token = getToken();

    try {
      const res = await fetch(`${API_URL}/development/banner-ads/review`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();

      setBannerAds(data);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const approve = async (id) => {
    const token = getToken();
    const res = await fetch(
      `${API_URL}/development/approve-banner-ad?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.json();

    if (data.status === "active") {
      setApnAPIMessage({
        success: true,
        message: "Approved Banner Ad.",
      });
      await getBannerAds();
    } else {
      setApnAPIMessage({
        success: false,
        message: data.message,
      });
    }
  };

  useEffect(() => {
    getBannerAds();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Link to="/banner-advertisements">
            <button type="button" className="btn btn-primary">
              BACK
            </button>
          </Link>
          <div className={"col-12"} style={{ marginTop: 20, paddingTop: 20 }}>
            <h1>Banner Ads In Review</h1>
          </div>
          <div className={"col-12"}>
            <div className={"table-responsive"}>
              <table className={"table"}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Website Link</th>
                    <th>Image URL</th>
                    <th>Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {bannerAds.map((bannerAd) => (
                    <tr key={bannerAd.id}>
                      <td>{bannerAd.createdAt}</td>
                      <td>
                        {" "}
                        <a target={"_blank"} href={bannerAd.websiteLink}>
                          {bannerAd.websiteLink}
                        </a>
                      </td>
                      <td>
                        <img
                          src={bannerAd.url}
                          height={50}
                          width={50}
                          alt="banner-ad"
                        />
                        <a href={bannerAd.url}>DOWNLOAD</a>
                      </td>
                      <td>
                        <button
                          className={"btn btn-success"}
                          onClick={() => {
                            approve(bannerAd.id);
                          }}
                        >
                          APPROVE
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {apnAPIMessage ? (
            <div className={"col-12"}>
              <p
                id={"apn-api-message"}
                style={{ color: apnAPIMessage.success ? "green" : "red" }}
              >
                {apnAPIMessage.message}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Review;
