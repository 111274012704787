import { Formik, Form, Field } from "formik";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getToken } from "../../../Common/utils";
import { API_URL } from "../../../Configs/api.config";

const AddArticle = () => {
  const { id } = useParams();
  const history = useHistory();

  const [articleType, setArticleType] = useState("internal");
  const [UIDisabled, setUIDisabled] = useState(false);

  const addArticle = async (values) => {
    if (UIDisabled === true) {
      return;
    }

    setUIDisabled(true);

    const data = {
      title: values.title,
      type: articleType,
      categoryId: id,
      shouldTriggerAPN: values.shouldTriggerAPN === true ? true : false,
    };

    if (articleType === "internal") {
      data["content"] = values.content;
      data["imageURL"] = values.imageURL ?? null;
    }

    if (articleType === "external") {
      data["content"] = values.content ?? null;
      data["externalLink"] = values.externalLink ?? null;
    }

    const token = getToken();

    const res = await fetch(`${API_URL}/development/add-article`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const body = await res.json();
    if (body.message) {
      alert(body.message);
      return;
    }

    history.push(`/articles/${id}`);
  };

  const validate = (values) => {
    const errors = {};
    if (values.title.length > 250) {
      errors.title = "Title is too long. Must be less than 250 characters";
    }
    return errors;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Link to={`/articles/${id}`}>
            <button className="btn btn-primary">BACK</button>
          </Link>
        </div>
        <div className="col-12">
          <Formik
            validate={validate}
            initialValues={{
              title: "",
              content: "",
              type: "internal",
              externalLink: "",
              imageURL: "",
              shouldTriggerAPN: false,
            }}
            onSubmit={async (values) => {
              addArticle(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="field-area">
                  <label>Article Title</label>
                  <Field type="text" className="form-control" name="title" />
                  <span className="formik-error">{errors.title}</span>
                </div>
                <div className="field-area">
                  <label>Article Type</label> <br />
                  <span style={{ marginRight: 30 }}>
                    <Field
                      type="radio"
                      name="type"
                      value="internal"
                      checked={articleType === "internal"}
                      onChange={() => {
                        setArticleType("internal");
                      }}
                    />
                    Internal
                  </span>
                  <span>
                    <Field
                      type="radio"
                      name="type"
                      value="external"
                      checked={articleType === "external"}
                      onChange={() => {
                        setArticleType("external");
                      }}
                    />
                    External
                  </span>
                </div>
                <div className="field-area">
                  <label>Send Push Notifications?</label> <br />
                  <Field type="checkbox" name="shouldTriggerAPN" />
                </div>
                <div className="field-area">
                  <label>Content</label>
                  <Field
                    as="textarea"
                    className="form-control"
                    name="content"
                  />
                </div>
                {articleType === "internal" ? (
                  <React.Fragment>
                    <div className="field-area">
                      <label>Image URL</label>
                      <Field
                        type="text"
                        name="imageURL"
                        className="form-control"
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="field-area">
                      <label>External Link</label>
                      <Field
                        type="text"
                        name="externalLink"
                        className="form-control"
                      />
                    </div>
                  </React.Fragment>
                )}
                <button
                  disabled={UIDisabled}
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{ marginTop: 20 }}
                >
                  SAVE
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
