import moment from "moment";
import { getToken } from "../../../Common/utils";
import { API_URL } from "../../../Configs/api.config";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const UserRow = ({ user }) => {
  const client = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    ["/user", user.id],
    async (type) => {
      const token = getToken();

      const { data } = await axios.get(
        `${API_URL}/development/change-user-type?user_uid=${user.uid}&account_type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(data);

      return data;
    },
    {
      onSuccess: (data) => {
        client.invalidateQueries("/users");
        alert(
          `User ${user.email} changed to ${
            data.accountTypeId == 0
              ? "CITIZEN"
              : data.accountTypeId == 1
              ? "PENDING"
              : "LEOSA"
          }`
        );
      },
    }
  );

  const handleChange = (e) => {
    mutate(e.target.value);
  };

  return (
    <tr>
      <td>{user.id}</td>
      <td>{user.uid}</td>
      <td>{moment(user.createdAt).format("MM/DD/YYYY")}</td>
      <td style={{ color: isLoading ? "grey" : "black" }}>
        <select
          style={{ width: 120 }}
          className="form-control"
          value={user.accountTypeId}
          onChange={handleChange}
        >
          <option value="0">CITIZEN</option>
          <option value="1">PENDING</option>
          <option value="2">LEOSA</option>
        </select>
      </td>
      <td>{user.username}</td>
      <td>{user.email}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.bullets}</td>
      <td>{user.badgesPosted}</td>
      <td>{user.referralsReceived}</td>
    </tr>
  );
};

export default UserRow;
