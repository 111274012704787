import React, { useState, useEffect } from "react";
import moment from "moment";

import { API_URL } from "../../Configs/api.config";
import { getToken } from "../../Common/utils";

const RecentClaims = () => {
  const [claims, setClaims] = useState([]);

  const token = getToken();

  const getClaims = async () => {
    const res = await fetch(`${API_URL}/development/recent-claims`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    console.log(data);

    setClaims(data);
  };

  useEffect(() => {
    getClaims();
  }, []);

  return (
    <div className="container">
      {" "}
      <div className="row">
        <div className={"col-12"} style={{ marginTop: 20, paddingTop: 20 }}>
          <h1>Recent Business Claims</h1>
        </div>
        <div className={"col-12"}>
          <div className={"table-responsive"}>
            <table className={"table"} style={{ display: "inline" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Place ID</th>
                  <th>Google Places URL</th>
                  <th>Claim Code</th>
                  <th>User Email</th>
                </tr>
              </thead>
              <tbody>
                {claims.map((claim) => (
                  <tr key={claim.id}>
                    <td>{moment(claim.dateOfClaim).format("MM/DD/YYYY")}</td>
                    <td>{claim.placeId}</td>
                    <td>
                      <a target={"_blank"} href={claim.googleBusinessUrl}>
                        URL
                      </a>
                    </td>
                    <td>{claim.code}</td>
                    <td>{claim.userEmail}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default RecentClaims;
