import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../../Common/utils";

import { API_URL } from "../../../Configs/api.config";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const token = getToken();
    const res = await fetch(`${API_URL}/development/categories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const categories = await res.json();

    setCategories(categories.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const deleteCategory = async (id) => {
    const token = getToken();

    const res = await fetch(`${API_URL}/development/delete-category`, {
      method: "POST",
      body: JSON.stringify({
        id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    if (data.success === true) {
      //remove category from state
      let tmp = categories
        .filter((a) => a.id !== id)
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(tmp);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Categories</h1>
        </div>
        <div className="col-12">
          <Link to={`/add-category`}>
            <button type="button" className="btn btn-success">
              CREATE
            </button>
          </Link>
        </div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>LEOSA Only</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={category.id}>
                    <td>
                      <Link to={`/articles/${category.id}`}>
                        {category.name}
                      </Link>
                    </td>
                    <td>{category.leosaOnly === 1 ? "YES" : "NO"} </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          deleteCategory(category.id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <Link to={`/edit-category/${category.id}`}>
                        <button type="button" className="btn btn-primary">
                          EDIT
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
