import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getToken } from "../../Common/utils";
import { API_URL } from "../../Configs/api.config";

const ActionButton = ({ action, id, email }) => {
  const client = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    ["/leosa-request/action", id],
    async () => {
      const token = getToken();

      const { data } = await axios.post(
        `${API_URL}/development/leosa-request/action`,
        {
          id,
          action,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries("/leosa-requests");
      },
      onError: (error) => {
        if (error.message) {
          alert(error.message);
        } else {
          alert("Could not complete LEOSA Request.");
        }
      },
    }
  );

  return (
    <button
      type="button"
      onClick={() => {
        if (
          window.confirm(
            `Are you sure you want to ${action} LEOSA for ${email}?`
          )
        ) {
          mutate();
        }
      }}
    >
      {action}
    </button>
  );
};

export default ActionButton;
